/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/

:root {
  --ion-font-family: orkneylight;
  --ion-color-primary: #317d6c;
  --ion-color-primary-rgb: 49, 125, 108;
  --ion-color-primary-contrast: #ffffff; /* The opposite of the base color, should be visible against the base color */
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #317d6c; /* A slightly darker version of the base color */
  --ion-color-primary-tint: #317d6c; /* A slightly lighter version of the base color */

  --ion-color-secondary: #6fbd8b;
  --ion-color-secondary-rgb: 111, 189, 139;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #6fbd8b;
  --ion-color-secondary-tint: #6fbd8b;

  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-transparent: rgba(255, 255, 255, 0.001);
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: rgb(255, 255, 255);
  --ion-color-white-contrast: #ffffff;
  --ion-color-white-shade: #ffffff;
  --ion-color-white-tint: #ffffff;

  /* ADDING A NEW COLOR  (remember to also add class) 
   USE COLOR TOOL : https://ionicframework.com/docs/theming/colors */
  /*
  --ion-color-favorite: #69bb7b;
  --ion-color-favorite-rgb: 105, 187, 123;
  --ion-color-favorite-contrast: #ffffff;
  --ion-color-favorite-contrast-rgb: 255, 255, 255;
  --ion-color-favorite-shade: #5ca56c;
  --ion-color-favorite-tint: #78c288;
*/
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 252, 248, 246;
  --ion-text-color: #000000;
  --ion-text-color-rgb: 79, 60, 117;
  --ion-border-color: #eaac9a;
  --ion-box-shadow-color: #dcb3af;

  /* TAB BAR */
  --ion-tab-bar-background: #000000;
  --ion-tab-bar-background-focused: #ffffff;
  --ion-tab-bar-border-color: none;
  --ion-tab-bar-color: #d4d7ff;
  --ion-tab-bar-color-selected: white;
  --ion-toolbar-background: none;
  --ion-toolbar-border-color: none;
  --ion-toolbar-color: #000000;
  --ion-item-background: #fcf8f6;
  --ion-item-border-color: none;
  --ion-item-color: #000000;

  --ion-safe-area-top: 0px;

  --bar-background: white;
}

/* NEW COLORS MUST HAVE THEIR CLASS ADDED TOO .ion-colo-{COLOR}
.ion-color-favorite {
  --ion-color-base: var(--ion-color-favorite);
  --ion-color-base-rgb: var(--ion-color-favorite-rgb);
  --ion-color-contrast: var(--ion-color-favorite-contrast);
  --ion-color-contrast-rgb: var(--ion-color-favorite-contrast-rgb);
  --ion-color-shade: var(--ion-color-favorite-shade);
  --ion-color-tint: var(--ion-color-favorite-tint);
}
*/

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-page {
  max-width: 470px;
  margin: 0 auto;
  box-shadow: 0 0 32px -6px black;
  background: white;
}

ion-toolbar {
  --border-width: 0px !important;
  --background: var(--toolbar-background);
  /* backdrop-filter: blur(4px); */
}
.swiper-pagination-bullet-active {
  background: black !important;
}

.ff-conforta {
  font-family: comfortaaregular;
}
.ff-lato {
  font-family: latoregular;
}
.ff-monthoers {
  font-family: swistblnk_monthoersregular;
}
